// import logo from './logo.svg';
import './App.css';
import Home from './page/Home';

function App() {
  return (
   <Home/>
  );
}

export default App;
